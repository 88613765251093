import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const AlertErrorIcon = ({ customStyles = styles }) => (
  <CustomIcon style={customStyles}>
    <g id="Form-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Forms---Text-Fields-and-Areas" transform="translate(-334.000000, -818.000000)" fill="#DA1E28">
        <g id="Group-2-Copy" transform="translate(16.000000, 227.000000)">
          <g id="Group-3" transform="translate(103.000000, 22.000000)">
            <g id="text-field-/-default-/-label/-error" transform="translate(0.000000, 532.000000)">
              <g id="Error" transform="translate(0.000000, 24.000000)">
                <g id="24/actions/status/AlertCircleFilled_18_N" transform="translate(215.000000, 13.000000)">
                  <path
                    d="M7,0.333333289 C3.31810164,0.333333289 0.333333289,3.31810164 0.333333289,7 C0.333333289,10.6818984 3.31810164,13.6666667 7,13.6666667 C10.6818984,13.6666667 13.6666667,10.6818984 13.6666667,7 C13.6666667,5.23189006 12.9642878,3.53619729 11.7140452,2.28595476 C10.4638027,1.03571223 8.76810994,0.333333289 7,0.333333289 Z M5.91666666,2.32291664 C5.90577604,2.21001708 5.96029936,2.10080866 6.05708333,2.04166663 C6.35184907,1.92192404 6.66607227,1.85724348 6.98416667,1.8508333 C7.29995555,1.84691642 7.61421126,1.8953501 7.91416667,1.99416663 C8.01802437,2.05178699 8.081992,2.16165139 8.08084882,2.28041664 L8.08084882,3.30124998 C8.08084882,4.50333332 7.84041667,7.83916667 7.79625001,8.27916668 C7.79542437,8.40043517 7.69645188,8.49807573 7.57518334,8.49726344 L7.55166667,8.49583334 L6.41666666,8.49583334 C6.29841139,8.49957292 6.19556271,8.41540782 6.17583333,8.29875001 C6.13416666,7.73041667 5.91666666,4.50708332 5.91666666,3.34416664 L5.91666666,2.32291664 Z M7,11.5208334 C6.69544488,11.5477818 6.39386598,11.4434655 6.17105197,11.2340999 C5.94823795,11.0247343 5.82537281,10.7302248 5.83333333,10.4245834 C5.82850433,10.1306706 5.94063112,9.84687782 6.14504644,9.63563771 C6.34946177,9.4243976 6.62942051,9.30301438 6.92333333,9.29819168 C6.94888889,9.29776946 6.97444444,9.29823335 7,9.29958335 C7.29025412,9.28090401 7.5760405,9.37829291 7.79448921,9.57032555 C8.01293792,9.76235818 8.14615472,10.0333042 8.16483334,10.3235584 C8.16699445,10.3571917 8.16760556,10.3908667 8.16666667,10.4245834 C8.17462719,10.7302248 8.05176205,11.0247343 7.82894803,11.2340999 C7.60613402,11.4434655 7.30455512,11.5477818 7,11.5208334 Z"
                    id="24-AlertCircleFilled_18_N"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </CustomIcon>
);

export default AlertErrorIcon;
