import React from 'react';

export const styles = {
  width: '18px',
  verticalAlign: 'bottom',
  marginLeft: '5px',
};

const CustomIcon = ({ children, fill, style }: { children: unknown; fill?: string; style?: unknown }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
    xmlSpace="preserve"
    fill={fill || 'currentColor'}
    style={style}
  >
    <g>{children}</g>
  </svg>
);

export default CustomIcon;
