import React from 'react';

const DefaultIcon = ({ children, fill }: { children: any; fill?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
    xmlSpace="preserve"
    fill={fill || 'currentColor'}
  >
    <g>{children}</g>
  </svg>
);

export default DefaultIcon;
