import React from 'react';
import { Footer as AntdFooter } from 'antd/lib/layout/layout';
import { TOS_ENDPOINT, PRIVACY_ENDPOINT, TOU_ENDPOINT } from '../../utils/constants';

const Footer: React.FC = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  return (
    <AntdFooter className="main-footer">
      <div className="footer-content">
        <div>
          <a href={`${baseUrl}${TOS_ENDPOINT}`} target="_blank" data-test-id="footer-link-tos" rel="noreferrer">
            Terms Of Service
          </a>
          {' | '}
          <a href={`${PRIVACY_ENDPOINT}`} target="_blank" data-test-id="footer-link-privacy" rel="noreferrer">
            Privacy Policy
          </a>
          {' | '}
          <a href={`${TOU_ENDPOINT}`} target="_blank" data-test-id="footer-link-tou" rel="noreferrer">
            Terms Of Use
          </a>
        </div>
        <div>© {new Date().getFullYear()} PowerSchool Group LLC. All Rights Reserved.</div>
      </div>
    </AntdFooter>
  );
};

export default Footer;
