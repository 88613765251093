import { Spin } from 'antd';
import * as React from 'react';

const Spinner = (): React.ReactElement => {
  return (
    <div className="loading_container">
      <Spin tip="Loading..." size="large" />
    </div>
  );
};

export default Spinner;
