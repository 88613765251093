import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const CheckmarkCircleIcon = () => (
  <CustomIcon style={styles}>
    <g id="NEW-FINAL-HISTORY-TABLES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Initial-dashboard-history" transform="translate(-964.000000, -490.000000)">
        <g id="bottom-rows" transform="translate(74.000000, 397.000000)">
          <g id="source-col-bottom" transform="translate(850.000000, 0.000000)"></g>
          <g id="status-col-bottom" transform="translate(502.000000, 0.000000)">
            <g id="Group-14" transform="translate(378.000000, -0.000000)">
              <g id="Table/cell/short/basic" transform="translate(3.000000, 81.000000)"></g>
              <g id="Spectrum/16//CheckmarkCircle_18_N" transform="translate(10.000000, 93.000000)" fill="#10D097">
                <path
                  d="M8,0.888888859 C4.07264176,0.888888859 0.888888859,4.07264176 0.888888859,8 C0.888888859,11.9273582 4.07264176,15.1111111 8,15.1111111 C11.9273582,15.1111111 15.1111111,11.9273582 15.1111111,8 C15.1111111,6.11401606 14.3619069,4.30527712 13.0283149,2.97168509 C11.6947229,1.63809306 9.88598394,0.888888859 8,0.888888859 Z M12.7404445,4.92444443 L7.11911111,12.1515556 C7.0247348,12.2723086 6.88318242,12.3468865 6.73022222,12.3564446 L6.69777777,12.3564446 C6.55628641,12.3565557 6.42054956,12.3004384 6.32044444,12.2004445 L2.86044442,8.73688889 C2.76025104,8.6370428 2.70382526,8.50148433 2.70357941,8.36003507 C2.70333517,8.2185858 2.75929093,8.08283264 2.85913776,7.98264 L2.86044442,7.98133333 L3.44977776,7.39244444 C3.54962031,7.29224752 3.68517679,7.23581693 3.82662606,7.23556688 C3.96807533,7.23531684 4.10383048,7.29126781 4.20402665,7.39111111 L4.20533332,7.39244444 L6.57777777,9.76977779 L11.2506667,3.76577776 C11.3374265,3.65406134 11.4650122,3.58138583 11.6053561,3.56373906 C11.7457001,3.5460923 11.8873061,3.58491982 11.9990222,3.67167998 L12,3.67244443 L12.6466667,4.17777776 C12.8781216,4.35842485 12.9200386,4.69217154 12.7404445,4.92444443 Z"
                  id="24-CheckmarkCircle_18_N"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </CustomIcon>
);

export default CheckmarkCircleIcon;
