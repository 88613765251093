import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const AlertYellowIcon = (props: Record<string, unknown>): React.ReactElement => (
  <CustomIcon style={props?.style ? props?.style : styles}>
    <g id="NEW-FINAL-HISTORY-TABLES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Initial-dashboard-history-beta-message" transform="translate(-960.000000, -778.000000)" fill="#FCD703">
        <g id="table-Group" transform="translate(74.000000, 447.000000)">
          <g id="status-col-bottom" transform="translate(502.000000, 0.000000)">
            <g id="Group-14" transform="translate(378.000000, -0.000000)">
              <g id="Spectrum/16/Alert_18_N-Copy-3" transform="translate(6.000000, 331.000000)">
                <path
                  d="M8.08737676,1.14622219 L0.181364238,14.4497778 C0.0995628746,14.5873576 0.101374917,14.7553865 0.186126508,14.8913782 C0.270878098,15.0273698 0.425889265,15.1111111 0.593514828,15.1111111 L16.4064852,15.1111111 C16.5741107,15.1111111 16.7291219,15.0273698 16.8138735,14.8913782 C16.8986251,14.7553865 16.9004371,14.5873576 16.8186358,14.4497778 L8.91262324,1.14622219 C8.82910189,1.00564365 8.67124849,0.918542285 8.5,0.918542285 C8.32875151,0.918542285 8.17089811,1.00564365 8.08737676,1.14622219 L8.08737676,1.14622219 Z M9.44529952,13.1111111 C9.44529952,13.2338411 9.33949327,13.3333334 9.20897464,13.3333334 L7.79102536,13.3333334 C7.66050673,13.3333334 7.55470048,13.2338411 7.55470048,13.1111111 L7.55470048,11.7777778 C7.55470048,11.6550478 7.66050673,11.5555556 7.79102536,11.5555556 L9.20897464,11.5555556 C9.33949327,11.5555556 9.44529952,11.6550478 9.44529952,11.7777778 L9.44529952,13.1111111 Z M9.44529952,10.4444445 C9.44529952,10.5671744 9.33949327,10.6666667 9.20897464,10.6666667 L7.79102536,10.6666667 C7.66050673,10.6666667 7.55470048,10.5671744 7.55470048,10.4444445 L7.55470048,5.1111111 C7.55470048,4.98838115 7.66050673,4.88888888 7.79102536,4.88888888 L9.20897464,4.88888888 C9.33949327,4.88888888 9.44529952,4.98838115 9.44529952,5.1111111 L9.44529952,10.4444445 Z"
                  id="24-Alert_18_N"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </CustomIcon>
);

export default AlertYellowIcon;
