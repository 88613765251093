import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const MoreVerticalIcon = () => (
  <CustomIcon style={styles}>
    <g id="NEW-FINAL-HISTORY-TABLES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Initial-dashboard-history" transform="translate(-1325.000000, -453.000000)">
        <rect x="0" y="0" width="1440" height="899"></rect>
        <rect id="Rectangle" x="51" y="200" width="1360" height="657"></rect>
        <g id="table-Group" transform="translate(74.000000, 397.000000)">
          <g id="More-col-icons" transform="translate(1252.000000, 54.000000)">
            <g id="Spectrum/16/MoreSmallListVert_18_N-Copy-10" transform="translate(11.000000, 0.000000)"></g>
          </g>
          <g id="student-records-row" transform="translate(212.000000, 42.000000)">
            <g id="Table/cell/short/basic" transform="translate(1018.000000, 0.000000)">
              <rect id="Cell-Background" x="0" y="0.975609756" width="67" height="39.0243902"></rect>
              <text
                id="Type-something"
                fontFamily="IBMPlexSans, IBM Plex Sans"
                fontSize="14"
                fontWeight="normal"
                line-spacing="15"
              ></text>
            </g>
            <g
              id="Spectrum/16/MoreSmallListVert_18_N-Copy-7"
              transform="translate(1039.000000, 14.000000)"
              fill="#141497"
            >
              <path
                d="M8,11.5111111 C9.00638555,11.5111111 9.82222223,12.3269478 9.82222223,13.3333334 C9.82222223,14.3397189 9.00638555,15.1555556 8,15.1555556 C6.99361445,15.1555556 6.17777777,14.3397189 6.17777777,13.3333334 C6.17777777,12.3269478 6.99361445,11.5111111 8,11.5111111 Z M8,6.17777777 C9.00638555,6.17777777 9.82222223,6.99361445 9.82222223,8 C9.82222223,9.00638555 9.00638555,9.82222223 8,9.82222223 C6.99361445,9.82222223 6.17777777,9.00638555 6.17777777,8 C6.17777777,6.99361445 6.99361445,6.17777777 8,6.17777777 Z M8,0.844444415 C9.00638555,0.844444415 9.82222223,1.6602811 9.82222223,2.66666664 C9.82222223,3.67305219 9.00638555,4.48888887 8,4.48888887 C6.99361445,4.48888887 6.17777777,3.67305219 6.17777777,2.66666664 C6.17777777,1.6602811 6.99361445,0.844444415 8,0.844444415 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </CustomIcon>
);

export default MoreVerticalIcon;
