import React from 'react';
import DefaultIcon from '../DefaultIcon';

const LeftAngleIcon = ({ fill }: { fill?: string }) => (
  <DefaultIcon fill={fill}>
    <g id="NEW-FINAL-HISTORY-TABLES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Initial-dashboard-logfilepg" transform="translate(-62.000000, -225.000000)">
        <g id="Spectrum/16/ChevronLeft_18_N" transform="translate(62.000000, 225.000000)" fill="#141497">
          <path
            d="M3.33333305,7.98103887 C3.33322041,8.3217463 3.49639203,8.64845338 3.78675974,8.88890233 L9.97428362,14.0306698 C10.3472739,14.3987317 10.935895,14.5633474 11.5006536,14.4575395 C12.0654123,14.3517315 12.5121874,13.9931335 12.6591989,13.5276489 C12.8062104,13.0621643 12.6289577,12.5673694 12.19956,12.2445822 L12.1615807,12.2130099 L7.06848041,7.98103887 L12.1623558,3.74777918 C12.7089443,3.24484642 12.6934553,2.47589295 12.1269179,1.98845491 C11.5603805,1.50101687 10.6364602,1.46172054 10.013038,1.89854701 L9.97505871,1.93011927 L3.78753483,7.07188675 C3.49650165,7.31254473 3.33301291,7.639792 3.33333305,7.98103887 Z"
            id="24-ChevronLeft_18_N"
          ></path>
        </g>
      </g>
    </g>
  </DefaultIcon>
);

export default LeftAngleIcon;
